.project {
    cursor: pointer;
}

.project:hover > .image-zoom {
    transform: scale(1.05);
    transition: transform .2s;
}

.project:hover > .project-caption {
    color: #a03000;
}

.project-caption {
    padding: 5px;
    color: #000;
    line-height: 1.4;
}

.project-link {
    text-decoration: none !important;
}

