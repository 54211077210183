.footer-bs {
    background-color: #3c3d41;
	padding: 60px 0 0px 0;
    color: rgba(255,255,255,1.00);
    margin-top: 40px;

    box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.5);

}

.footer-creatorlink {
    font-size: small;
    padding: 20px 0 0 0;
    letter-spacing: 0.05em;
}

.footer-bs .footer-text {
    color:rgba(255,255,255,0.70);
}

.footer-bs .footer-link a {
    color:rgba(255,255,255,1.00);
}

.footer-bs .footer-link a:hover {
    color:rgba(255,255,255,0.80);
}

/*
.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px; 
    line-height: 60px; 
    background-color: #f5f5f5;
  }
*/