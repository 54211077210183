.navbar-bs {
    background-color: #fff;
}

.navbar-bs .navbar-nav .nav-link.menu-link {
    color: #212529 !important;
}

.navbar-bs .navbar-nav .nav-link.menu-link {
    color: #212529 !important;
}

.navbar-bs .navbar-nav .nav-link.phone-link {
    color: #362d87 !important;
}

.phone {
    margin-right: 5px;
}

a.dropdown-item:active {
    background-color: #999;
}

.btn.btn-outline-primary:active {
    background-color: #bf6102 !important;
}

.btn.btn-primary:active {
    background-color: #bf6102 !important;
}

.btn.btn-outline-primary:disabled {
    background-color: #bf6102 !important;
}

.btn.btn-primary:disabled {
    background-color: #bf6102 !important;
}

.btn:focus, .btn:active {
    outline: none !important;
    box-shadow: none !important;
}

.btn:active {
    border-color: #bf6102 !important;
  }