.carousel-indicators li {
    width: 3px;
    border-radius: 100%;
}

.carousel img {
    margin: auto;
}

.circles {
    margin-top: 60px;
    margin-bottom: 60px;
}

.products-header {
    margin-top: 75px;
}
.products {
    margin-top: 30px;
    margin-bottom: 60px;
}

.image-top {
    max-height: 210px;
    max-width: 70%;
    padding-bottom: 20px;
}