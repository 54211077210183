.error-link{
  color:rgba(255,255,255,1.00);
  padding: 2px;
}

.error-link:hover{
  color:rgba(255,255,255,1.00);
  text-decoration: none;
}

.error-text{
  color:rgba(255,255,255,0.70);
}