
/*
body{
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}*/

.selectDisable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.selectEnable { 
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
}

.shad 
{
  text-shadow: 0px 0px 3px rgba(255,255,255,0.2);
}

.shad2 
{
  text-shadow: 0px 0px 10px rgba(0,0,0,1);
}

.sticky-nav {
    position: fixed;
    top: 0;
    width: 100%;
    /*background-color: transparent !important;*/
    transition: height 300ms linear, background-color 500ms linear;
    z-index: 100;
}

.block-animate {
    background-color: white !important;
}

.hero-text
{
  position: absolute;
  top:100px;
  right: 0px;

  display: flex;
  text-align: right;
  
  padding-left: 45px;
  padding-right: 45px;

  color: white;

  line-height: 1.4;
  font-weight: 400;
}

.hero-button
{
  position: relative;
  bottom: 30px;
  
  left: 45px;

  display: flex;
  text-align: left;

  padding-left: 45px;
  padding-right: 45px;
}

h1 {
  font-size: 3em;
}

.deep-slider-block {
	/*background-color: #f5f8fb;*/
	background-color: #f7f7f7;
  height: 650px;
	/*width: 80%;*/
}

.deep-slider 
{
	position: relative;
	top: 70px;
	padding-left: 45px;
  padding-right: 45px;
}

.deep-slide-item {
	top: 0px;
	padding: 0px 0px;
	margin: 5px 10px;
	user-select: none;
	cursor: pointer;
	/*border: dotted;*/
	width: 350px;
	/*height: 500px;*/
}


.scroll-slide-arrow {
	padding: 20px;
	cursor: pointer;
}

.menu-item-wrapper.active {
  border: 0px blue dotted;
}
.menu-item.active {
  border: 0px green dotted;
}

.zeroborder {
  border: 0px red dotted;
}

@media (max-width: 900px) {
  h1 {
    font-size: 2em;
  }

  .deep-slider 
  {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 1em;
  }
  h2 {
    font-size: 1.5em;
  }
  .hero-text
  {
    top: 85px;
      padding-left: 10px;
      padding-right: 30px;
  }
  .notformob {
    display: none;
  }
  .deep-slider 
  {
    padding-left: 0px;
    padding-right: 0px;
  }
  .scroll-slide-arrow {
    padding: 7px;
  }
}

.VideoContainer {
  top: 60px;  
  position: relative; 
  overflow: hidden;
}

.Video {
  /*width: 100%;*/
  height: 100%; /*!important;*/
  width: 100%; /*!important;*/
}


#firstBlockRoot
{
  background-color: #fff;
}

.ddd-root {
  margin: auto;
  position: relative;
}
  
.ddd-model {
  position: absolute;
  bottom: 0;

  margin: auto;
  z-index: -1;
}

.logo-glow {
  filter: drop-shadow( 0px 0px 10px rgba(255, 255, 255, 0.5));
}



.deep-second-block {

	/*background-color: #f5f8fb;*/
  background-color: #f7f7f7;
  
  padding-top: 50px;
  padding-bottom: 50px;
}

.deep-third-block {
  margin-top: 50px;
}

.u-clients {
  width: 82px;
  margin: 8px;
}
