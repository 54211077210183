.circle-zoom {
    padding-bottom: 10px;
  }

.circle-link {
    color: #000;
    text-decoration: none !important;
}

.circle:hover > .circle-zoom {
    transform: scale(1.05);
    transition: transform .2s;
}

.circle:hover > .circle-link {
    color: #a03000;
}