body {
    
    font-family: Source Sans Pro,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif !important;
    -webkit-font-smoothing: antialiased;

    font-size: 1rem !important;
    font-weight: 400;
    line-height: 1.5 !important;
    color: #212529;
    text-align: left;
}

h2 {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 12px;
    padding-top: 12px;

}

li {
    list-style-type: none;
}

ul {
    padding-inline-start: 0px;
}

main {
    padding-top: 40px;
}

.left-menu-text {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;;
}

.left-menu-link {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
}

.left-menu-link:hover {
    text-decoration: none;
    color: #a03000;
}

a.dropdown-item:active {
    background-color: #999;
    text-decoration: none;
}

.ul-custom li {
    margin-top: 5px;
}

.ul-custom li:before {
    content:  "—";
    position: relative;
    margin-right: 3px;
}

.ol-custom {
    padding-inline-start: 0px;
    list-style-position: outside;
}

.ol-custom li {
    margin-top: 5px;
    list-style: decimal inside;
}

.ol-custom li:before {
    margin-right: 3px;
}