.btn-outline-primary {
    border-color: #ef8014;
    color: #ef8014;
}

.btn-outline-primary:hover {
    background-color: #ea7500;
    border-color: #ea7500;
}

.btn-outline-primary:active {
    background-color: #ef8014;
    border-color: #ef8014;
}

.btn-primary {
    border-color: #ef8014;
    background-color:  #ef8014;
}

.btn-primary:hover {
    background-color: #ea7500;
    border-color: #ea7500;
}

.btn-primary:active {
    background-color: #ef8014;
    border-color: #ef8014;
}